import React from 'react'
import * as Styled from './contentWithImageAndIconsStyles';
import { graphql, useStaticQuery } from 'gatsby';
//import CountUp from 'react-countup';

const InlineEmbedHandler = ({node} ) => {
    const assetId = node?.data?.target?.sys?.id;
    const {allContentfulMutipleImagesWithPath}  = useStaticQuery(
        graphql`query myQuery {
            allContentfulMutipleImagesWithPath(filter: {node_locale: {eq: "en-US"}}) {
            edges {
            node {
                id
                contentful_id
                type
                title
                images {
                title
                description
                url
                }
            }
            }
        }
        }
    `);

    const asset = allContentfulMutipleImagesWithPath?.edges?.find((edge) => {
        return edge?.node?.contentful_id === assetId;
    });
            return (
                    <>
                    {
                        asset?.node?.images.map((image,index)=>{
                            return <Styled.IconSubContainer key={index}>
                                            <Styled.Icon src={image?.url} alt='Icon'></Styled.Icon>
                                            <Styled.Header5>
                                                <Styled.Span>
                                                    {image?.title}
                                                    {/* <CountUp end={image?.title?.split(" ")[0]}></CountUp>
                                                    {' '+image.title.split(" ")[1]} */}
                                                </Styled.Span>
                                                {image?.description}
                                            </Styled.Header5>
                                    </Styled.IconSubContainer>
                        })
                    }</>
                );
}

export default InlineEmbedHandler;