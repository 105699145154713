import React from 'react'
import * as Styled from './contentWithImageAndIconsStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import InlineEmbedHandler from './inlineEmbedHandler';
import { Row, Col } from 'react-bootstrap';


const paragraphWithImage = (sectionData) =>{
    const optionsMainStyle = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <Styled.Heading1>{children}</Styled.Heading1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 style={{fontWeight: 'bold'}}>{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <Styled.Heading3 style={{fontWeight: 'bold'}}>{children}</Styled.Heading3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 >{children}</h4>
            ),
            [BLOCKS.HEADING_5]: (node, children) => (
                <h5>{children}</h5>
            ),
            [BLOCKS.HEADING_6]: (node, children) => (
                <Styled.Heading6>{children}</Styled.Heading6>
            ),
            [BLOCKS.UL_LIST]: (node, children) => (
                <ul>{children}</ul>
            ),
            [BLOCKS.LIST_ITEM]: (node, children) => (
                <Styled.ListItem>{children}</Styled.ListItem>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <Styled.Paragraph>{children}</Styled.Paragraph>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node?.data?.target?.sys?.id);
                if (asset) {
                return (
                    <Styled.IconSubContainer>
                    <Styled.Icon
                    src={asset?.node?.file?.url}
                    alt={asset?.node?.title}
                    /></Styled.IconSubContainer>
                );
                }
        },
        [INLINES.EMBEDDED_ENTRY]: (node, key) => (
            <Styled.IconContainer>

                <InlineEmbedHandler key={key} node={node} />
            </Styled.IconContainer>
        ),

        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };


    return(
        <Styled.MainDiv>
            {
                sectionData?.sectionData?.listOfImagesWithPath?.map((item,index)=>{
                    if(item!=null){
                    if(item?.type ==='leftSideImage'){
                    return(
                        <Row key={index} style={{ marginBottom :'30px'}}>
                        <Styled.GridRowLeft>
                        <Col >
                        <Styled.ImageContainer>
                        <Styled.Image src={item?.image?.file?.url} alt='img'></Styled.Image>
                        </Styled.ImageContainer>
                        </Col>
                        <Col>
                        <Styled.TextContainer>
                            
                        {documentToReactComponents(
                        JSON.parse(item?.richText?.raw),
                        optionsMainStyle
                        )}
                        </Styled.TextContainer>
                        </Col>
                    </Styled.GridRowLeft>
                    </Row>
                    );
                    }
                    else{
                        return(
                        <Row style={{ marginBottom :'30px'}}>
                        <Styled.GridRowRight>
                        <Col>
                        <Styled.TextContainer>
                            
                        {documentToReactComponents(
                        JSON.parse(item?.richText?.raw),
                        optionsMainStyle
                        )}
                        </Styled.TextContainer>
                        </Col>
                        <Col>
                        <Styled.ImageContainer>
                            <Styled.Image src={item?.image?.file?.url} alt='img'></Styled.Image>
                        </Styled.ImageContainer>
                        </Col>
                        </Styled.GridRowRight>
                        
                        </Row>
                        );
                    }
                    }
                    return<></>
                })
            }
        </Styled.MainDiv>
    );

};

export default paragraphWithImage;