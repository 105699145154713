import styled from "styled-components";
import bullet from "../../images/circle.svg";

export const MainDiv = styled.div`
  margin: 8% 8% 0% 8%;
  padding: 0;
  display: grid;
  padding-bottom: 25px;

  @media (max-width: 1024px) {
    margin: 5% 4% 0% 4%;
  }

  @media (max-width: 768px) {
    margin: auto;
    padding: 0;
    display: grid;
    width: 100%;
  }
`;

export const GridRowLeft = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    display: block;
    margin-top: 0;
  }
`;

export const GridRowRight = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    //display: block;
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TextContainer = styled.div`
  padding: 0px 50px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    padding: 0px 30px;
  }
  @media (max-width: 425px) {
    padding: 0px 15px;
  }
`;

export const ImageContainer = styled.div`
  padding: 0px 0px;
  display: grid;
  justify-items: center;
  @media (max-width: 1024px) {
    padding: 15px;
  }
  @media (max-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 425px) {
    padding:15px;
  }
`;

export const IconContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  //grid-template-columns: 33% 33% 33%;
  width: 100%;
  //grid-gap: 45px;
  @media (max-width: 1024px) {
    grid-gap: 40px;
  }
  @media (max-width: 768px) {
    grid-gap: 10px;
  }
`;

export const IconSubContainer = styled.div`
  display: inline-block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  @media (max-width: 768px) {
    width: 140px;
  }
  @media (max-width: 425px) {
    width: 80px;
  }
`;

export const Icon = styled.img`
  
  width: 165px;

  @media (max-width: 1280px) {
    width: 100px;
  }
  @media (max-width: 1024px) {
    width: 90px;
  }
  @media (max-width: 768px) {
    width: 120px;
  }
  @media (max-width: 425px) {
    width: 80px;
  }

  @media (max-width: 320px) {
    width: 70px;
  }

`;

export const Image = styled.img`
  width: 95%;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  margin: 35px 0px;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    text-align: center;

    text-align: start;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const Header5 = styled.h5`
  color: #007dc3;
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Header1 = styled.h1`
  @media (max-width: 768px) {
    text-align: center;
    font-weight: bold;
  }
`;

export const Span = styled.span`
  font-weight: bold;
  display: block;
  font-size: 14px;
  padding-bottom: 8px;

  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const Heading1 = styled.h1`
  font-weight: bold;
  line-height: 45px;
  margin-bottom: 10px;
  color: var(--font-color);
  font-size: 34px;
  @media (max-width: 1440px) {
    font-size: 28px;
  }
  @media (max-width: 425px) {
    line-height: 28px;
    font-size:22px;
  }
`;

export const Heading3 = styled.h3`
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0px;
  color: var(--font-color);
  @media (max-width: 1440px) {
    font-size: 20px;
  }
`;

export const ListItem = styled.li`
  list-style-image: url(${bullet});
  padding-left: 15px;

  ${Paragraph} {
    margin: 10px 0px;
  }
`;

export const Heading6 = styled.div`
  font-size: 16px;
  opacity: 0.7;
  font-style: italic;
`;
